// react-router-dom components
//import { Link } from "react-router-dom";

// @mui material components

// Material Kit 2 React components
//import MKBox from "components/MKBox";

import MKTypography from "components/MDTypography";
import { Container, Grid, Card } from "@mui/material";

import CardBG1 from "../../../assets/images/Feature_Image4.jpg";
import CardBG2 from "../../../assets/images/Feature_Image2.jpg";
import CardBG3 from "../../../assets/images/Feature_Image.jpg";
// Data
const features = [
  {
    title: "Optimize Bar Stock Material",
    description: "Maximize material usage with precise cut planning for bar stock materials.",
    imgUrl: CardBG1,
  },
  {
    title: "Custom Saw Kerf Settings",
    description: "Account for custom saw kerf settings to ensure every cut is perfect.",
    imgUrl: CardBG2,
  },
  {
    title: "Factory Edge Precision",
    description: "Automatically set material ends for clean, factory-edge finishes.",
    imgUrl: CardBG3,
  },
];
const FeaturedSection = () => {
  return (
    <Container sx={{ py: 8 }}>
      <MKTypography variant="h4" align="center" gutterBottom>
        Key Features
      </MKTypography>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                backgroundImage: `url(${feature.imgUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "white",
                padding: 2,
                "&:hover": {
                  transform: "scale(1.05)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            >
              <MKTypography
                variant="h6"
                gutterBottom
                color="white"
                fontSize="1.2em"
                fontWeight="bold"
                sx={{
                  height: "10rem",
                  textShadow:
                    "0 1px 7px #000, 0 1px 7px #000, 0 3px 7px #000,0 3px 7px #000, 0 3px 7px #000",
                }}
              >
                {feature.title}
              </MKTypography>
              <MKTypography
                variant="body2"
                color="white"
                fontSize="1em"
                fontWeight="bold"
                sx={{
                  height: "10rem",
                  textShadow:
                    "0 1px 7px #000, 0 1px 7px #000, 0 3px 7px #000,0 3px 7px #000, 0 3px 7px #000",
                }}
              >
                {feature.description}
              </MKTypography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FeaturedSection;
