/*eslint-disable no-unused-vars*/
import { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material/styles";
import themeDark from "../assets/theme";
import Loading from "components/Loading";
import MDSnackbar from "components/MDSnackbar";

// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from "firebase/app";

import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

//import { getStorage } from "firebase/storage";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  onSnapshot,
  getDocs,
  getDoc,
  query,
  where,
  setDoc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore"; // Correct imports
import { FamilyRestroomTwoTone } from "@mui/icons-material";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const authType = getAuth();
const provider = new GoogleAuthProvider();

// Material Dashboard 2 React main context
const MaterialUI = createContext();
export const ProjectContext = createContext();
export const AuthContext = createContext();
// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "Optim";
export const AuthProvider = ({ children }) => {
  const [AppcurrentUser, setAppcurrentUser] = useState(null);
  const [Role, setRole] = useState(null);
  const [Subscription, setSubscription] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState();
  const closeWarningSB = () => setWarningSB(false);
  const [warningMess, setWarningMess] = useState([""]);

  const navigate = useNavigate();
  const renderWarningSB = (
    <ThemeProvider theme={themeDark}>
      <MDSnackbar
        color="warning"
        icon="star"
        title={warningMess[0]}
        content={warningMess[1] || ""}
        dateTime="now"
        open={warningSB}
        onClose={closeWarningSB}
        close={closeWarningSB}
      />
    </ThemeProvider>
  );
  useEffect(() => {
    const fetchProductsAndPrices = async () => {
      try {
        const cachedData = localStorage.getItem("productsWithPrices");

        if (cachedData) {
          setProducts(JSON.parse(cachedData));
          setLoading(false);
          return;
        }
        const productsData = await GetProductsAndPrices();

        setProducts(productsData);
        localStorage.setItem("productsWithPrices", JSON.stringify(productsData));
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchProductsAndPrices();
  }, []);

  useEffect(() => {
    const setupPersistenceAndAuth = async () => {
      try {
        await setPersistence(authType, browserLocalPersistence);
        //console.log("Auth persistence set to LOCAL");
      } catch (error) {
        //console.error("Error setting auth persistence:", error);
      }

      // Listening for auth state changes
      onAuthStateChanged(authType, (user) => {
        if (user) {
          //console.log("User is signed in:", user);
          setAppcurrentUser(user);
          getCustomClaimRole();
          GetSubscription(user);
          setIsLoggedIn(true);
        } else {
         // console.log("No user is signed in");
          setAppcurrentUser(null);
          setIsLoggedIn(false);
          if (
            window.location.pathname !== "/authentication/sign-in" &&
            window.location.pathname !== "/authentication/sign-up" &&
            window.location.pathname !== "/authentication/reset-password" &&
            window.location.pathname !== "/Home"
          ) {
            navigate("/Home"); // Adjust to your login page if needed
          }
        }
      });
    };

    setupPersistenceAndAuth();
  }, [navigate]);

  async function getCustomClaimRole() {
    const auth = getAuth(); // Initialize the Auth instance
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error("No user is currently signed in.");
    }
    // Force a refresh of the ID token to ensure custom claims are updated
    await currentUser.getIdToken(true);
    const decodedToken = await currentUser.getIdTokenResult();
    setRole(decodedToken.claims.stripeRole);

  }

  const GetSubscription = async (user) => {
    try {
      const customersRef = collection(db, "customers", user.uid, "subscriptions");
      const subscriptionsQuery = query(customersRef, where("status", "in", ["trialing", "active"]));
      onSnapshot(subscriptionsQuery, (snapshot) => {
        if (!snapshot.empty) {
          // Assuming one active or trialing subscription exists
          const doc = snapshot.docs[0];
          setSubscription(doc.data());
      
        } else {
          console.log("No active or trialing subscriptions found.");
          setRole("none");
        }
      });
    } catch (err) {
      console.error("Error fetching subscriptions: ", err);
    }
  };

  const GetProductsAndPrices = async () => {
    try {
      const productsRef = collection(db, "products");
      const productsSnapshot = await getDocs(productsRef);

      if (!productsSnapshot.empty) {
        const products = [];

        for (const productDoc of productsSnapshot.docs) {
          const productData = productDoc.data();
          const pricesRef = collection(db, "products", productDoc.id, "prices");
          const pricesSnapshot = await getDocs(pricesRef);

          const prices = pricesSnapshot.docs.map((priceDoc) => ({
            id: priceDoc.id,
            ...priceDoc.data(),
          }));

          products.push({
            id: productDoc.id,
            ...productData,
            prices,
          });
        }

        console.log("Products with prices:", products);
        return products; // Return the combined data
      } else {
        console.log("No products found.");
        return [];
      }
    } catch (err) {
      console.error("Error fetching products and prices: ", err);
      throw err;
    }
  };
  //console.log(GetSubscription());
  const CheckoutSession = async (priceID) => {
    try {
      const customersCollection = collection(
        db,
        "customers",
        AppcurrentUser.uid,
        "checkout_sessions"
      );

      const docRef = await addDoc(customersCollection, {
        price: priceID,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        metadata: {
          item: "item001",
        },
      });

      // Listen for updates to the new document
      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          alert(`An error occurred: ${error.message}`);
        }
        if (url) {
          window.location.assign(url); // Redirect to Stripe Checkout URL
        }
      });
    } catch (err) {
      console.error("Error creating checkout session: ", err);
    }
  };

  const handleSignUp = async (email, password, Name) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(authType, email, password);
      const user = userCredential.user;
      await updateProfile(user, {
        displayName: Name,
      });
      navigate("/subscription");
    } catch (err) {
      console.log(err);
    }
  };
  const PortalLink = async () => {
    try {
      setLoading(true);
      const app = getApp();
      const functions = getFunctions(app, "us-central1");
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-4715-createPortalLink"
      );

      const { data } = await functionRef({
        returnUrl: window.location.origin + "/Account",
        locale: "auto",
      });

      console.log("Received data from Cloud Function:", data);
      window.location.assign(data.url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in PortalLink function:", error);
    }
  };
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(authType, provider);
      const user = result.user;

      // Check if user document exists
      const userDocRef = doc(db, "customers", user.uid);
      const userDoc = await getDoc(userDocRef);
      //console.log(userDoc.data());
      // If user does not exist in Firestore, create a new user document
      if (!userDoc.exists()) {
        // If the user document doesn't exist, create one
        await setDoc(userDocRef, {
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          createdAt: new Date().toISOString(),
        });
        console.log("New user created in Firestore.");
      }
      setAppcurrentUser(user);
      navigate("/subscription");
    } catch (error) {
      console.log(error);
      let errorMessage = "An error occurred during sign-in. Please try again.";
      let errorTitle = "Error";
      // Determine the specific error
      if (error.code === "auth/user-disabled") {
        errorMessage = "Your account has been disabled by the administrator.";
        errorTitle = "Account Disabled";
      } else if (error.code === "No Account") {
        errorMessage = "No Account exists with those creditials";
        errorTitle = "No Account";
      } else if (error.code === "auth/popup-closed-by-user") {
        errorMessage = "The popup has been closed before completing the sign-in process.";
        errorTitle = "Popup Closed";
      } else if (error.code === "auth/cancelled-popup-request") {
        errorMessage = "The popup has been closed before completing the sign-in process.";
        errorTitle = "Popup Closed";
      } else if (error.code === "auth/network-request-failed") {
        errorMessage = "Network error occurred. Please check your connection and try again.";
        errorTitle = "Network Error";
      } else if (error.code === "auth/account-exists-with-different-credential") {
        errorMessage =
          "An account already exists with the same email address but different sign-in credentials.";
        errorTitle = "Account Exists";
      }
      setWarningSB(true);
      setWarningMess([errorTitle, errorMessage]);
      //console.error("Error signing in with Google: ", error);
      //throw error;
    }
  };
  const login = (userEmail, Username, UserId) => {
    // Perform login logic
    // Assuming login is successful, update isLoggedIn state and store it
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("UserName", userEmail);
    localStorage.setItem("User_Name", Username);
    localStorage.setItem("User_ID", UserId);
  };

  const logout = async () => {
    try {
      await signOut(authType);
      setAppcurrentUser(null); // Reset the user state
      setIsLoggedIn(false); // Update login status
      navigate("/authentication/sign-in");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authType,
        isLoggedIn,
        login,
        logout,
        signInWithGoogle,
        AppcurrentUser,
        handleSignUp,
        CheckoutSession,
        Role,
        Subscription,
        PortalLink,
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {children}
          {renderWarningSB}
        </>
      )}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired, // Ensure children prop is provided and of type node
};
export const ProjectProvider = ({ children }) => {
  // State to hold the project list
  const [projects, setProjects] = useState([]);
  const { AppcurrentUser } = useContext(AuthContext);
  const [Inventory, setInventory] = useState([]);
  const [Materials, setMaterials] = useState([]);
  const [Vendors, setVendors] = useState([]);
  const [Cutlists, setCutlists] = useState([]);

  const navigate = useNavigate();
  // Function to fetch project list from the SQL database
  const fetchCutlists = async () => {
    const user = AppcurrentUser; // Ensure this contains the authenticated user object

    if (user) {
      try {
        // Reference the user's projects collection
        const MaterialTypesRef = collection(db, "customers", user.uid, "Cutlists");

        // Use onSnapshot to listen for real-time updates
        const unsubscribe = onSnapshot(
          MaterialTypesRef,
          (querySnapshot) => {
           // console.log("Cutlists Snapshot listener triggered.");

            if (querySnapshot.empty) {
              setCutlists([{ Name: "No Cutlists" }]);
            } else {
              const Cutlists = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
          
              setCutlists(Cutlists);
            }
          },
          (error) => {
            console.error("Error during snapshot listener: ", error);
          }
        );

        // Return the unsubscribe function for cleanup
        return unsubscribe;
      } catch (e) {
        console.error("Error fetching user's projects: ", e);
        return [];
      }
    } else {
      console.error("No user is signed in.");
      return [];
    }
  };
  const fetchMaterials = async () => {
    const user = AppcurrentUser; // Ensure this contains the authenticated user object

    if (user) {
      try {
        // Reference the user's projects collection
        const MaterialTypesRef = collection(db, "customers", user.uid, "MaterialTypes");

        // Use onSnapshot to listen for real-time updates
        const unsubscribe = onSnapshot(
          MaterialTypesRef,
          (querySnapshot) => {
            //console.log("Snapshot listener triggered.");

            if (querySnapshot.empty) {
              setMaterials([{ Name: "No Material" }]);
            } else {
              const materials = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              setMaterials(materials);
            }
          },
          (error) => {
            console.error("Error during snapshot listener: ", error);
          }
        );

        // Return the unsubscribe function for cleanup
        return unsubscribe;
      } catch (e) {
        console.error("Error fetching user's projects: ", e);
        return [];
      }
    } else {
      console.error("No user is signed in.");
      return [];
    }
  };

  // Fetch projects on component mount
  useEffect(() => {
    if (AppcurrentUser) {
      //console.log("regen all data");
      fetchMaterials();
      fetchProjects();
      fetchCutlists();
    }
  }, [AppcurrentUser]); // Empty dependency array ensures it runs only once on mount

  // Function to fetch projects from the SQL database

  const fetchProjects = () => {
    const user = AppcurrentUser; // Ensure this contains the authenticated user object

    if (user) {
     // console.log("Authenticated user: ", user); // Log user info to ensure authentication

      try {
        // Reference the user's projects collection
        const projectsRef = collection(db, "customers", user.uid, "projects");

        // Use onSnapshot to listen for real-time updates
        const unsubscribe = onSnapshot(
          projectsRef,
          (querySnapshot) => {
            //console.log("Snapshot listener triggered.");

            if (querySnapshot.empty) {
              setProjects([{ JobName: "No Projects" }]);
            } else {
              const projects = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              setProjects(projects);
            }
          },
          (error) => {
            console.error("Error during snapshot listener: ", error);
          }
        );

        // Return the unsubscribe function for cleanup
        return unsubscribe;
      } catch (e) {
        console.error("Error fetching user's projects: ", e);
        return [];
      }
    } else {
      console.error("No user is signed in.");
      return [];
    }
  };
  const addProject = async (data) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error("User is not authenticated");
      }

      const userId = currentUser.uid;

      const projectsRef = collection(db, "customers", userId, "projects");

      const docRef = await addDoc(projectsRef, {
        ...data,
        createdAt: serverTimestamp(),
      });

      console.log("Project created with ID: ", docRef.id);
      navigate(`/Projects/${docRef.id}`);
    } catch (e) {
      console.error("Error adding project: ", e);
    }
  };

  const addCutlist = async (data,bulk =false) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error("User is not authenticated");
      }

      const userId = currentUser.uid;

      const cutlistRef = collection(db, "customers", userId, "Cutlists");

      const docRef = await addDoc(cutlistRef, {
        ...data,
        createdAt: serverTimestamp(),
      });

      console.log("Cutlist created with ID: ", docRef.id);
      if(!bulk){
        navigate(`/optimizer/${docRef.id}`);
      }

    } catch (e) {
      console.error("Error adding project: ", e);
    }
  };
  const deleteProjectAndCutlists = async (projectId) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is signed in.");
      return;
    }

    const userId = user.uid;

    try {
      // Create a batch to perform multiple operations atomically
      const batch = writeBatch(db);
      // Reference to the project document
      const projectRef = doc(db, "customers", userId, "projects", projectId);
      // Fetch the project document using getDoc
      const projectDoc = await getDoc(projectRef);
      // Check if the project exists
      if (!projectDoc.exists()) {
        throw new Error("Project does not exist.");
      }
      // Add the project document deletion to the batch
      batch.delete(projectRef);
      // Reference to the cutlist collection (if it exists)
      const cutlistRef = collection(db, "customers", userId, "projects", projectId, "cutlists");
      const cutlistQuery = query(cutlistRef, where("projectId", "==", projectId));
      const cutlistSnapshot = await getDocs(cutlistQuery);

      // If cutlist documents exist, add them to the batch for deletion
      if (!cutlistSnapshot.empty) {
        cutlistSnapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });
      }
      // Commit the batch to delete project and associated cutlists (if any)
      await batch.commit();

      navigate("/Projects");
    } catch (error) {
      console.error("Error deleting project and cutlists: ", error);
    }
  };
  const deleteMaterialandCutLists = async (materialId) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is signed in.");
      return;
    }

    const userId = user.uid;
    try {
      // Create a batch to perform multiple operations atomically
      const batch = writeBatch(db);

      // Reference to the project document
      const projectRef = doc(db, "customers", userId, "MaterialTypes", materialId);

      // Fetch the project document using getDoc
      const projectDoc = await getDoc(projectRef);

      // Check if the project exists
      if (!projectDoc.exists()) {
        throw new Error("Material does not exist.");
      }

      // Add the project document deletion to the batch
      batch.delete(projectRef);

      // Reference to the cutlist collection (if it exists)
      const cutlistRef = collection(db, "customers", userId, "cutlists");
      const cutlistQuery = query(cutlistRef, where("materialId", "==", materialId));
      const cutlistSnapshot = await getDocs(cutlistQuery);

      // If cutlist documents exist, add them to the batch for deletion
      if (!cutlistSnapshot.empty) {
        cutlistSnapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });
      }

      await batch.commit();

      navigate("/Material");
    } catch (error) {
      console.error("Error deleting project and cutlists: ", error);
    }
  };

  const addMaterial = async (data) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error("User is not authenticated");
      }

      const userId = currentUser.uid;

      const projectsRef = collection(db, "customers", userId, "MaterialTypes");

      const docRef = await addDoc(projectsRef, {
        ...data,
        createdAt: serverTimestamp(),
      });

      //console.log("Project created with ID: ", docRef.id);

      navigate(`/Material/${docRef.id}`);
    } catch (e) {
      console.error("Error adding Material: ", e);
    }
  };
/*
  const FractionDecimal = (value) => {
    if (typeof value === "string") {
      var [wholeStr, fractionStr] = "";
      // Check if the input string contains a space character to determine if it's a mixed fraction
      if (value.includes("/")) {
        if (value.split(" ").length <= 1) {
          wholeStr = 0;
          fractionStr = value;
        } else {
          [wholeStr, fractionStr] = value.split(" ");
        }

        const wholeNumber = parseInt(wholeStr, 10);
        const [numeratorStr, denominatorStr] = fractionStr.split("/");
        const numerator = parseInt(numeratorStr, 10);
        const denominator = parseInt(denominatorStr, 10);
        // Convert the mixed fraction to a decimal value
        const floatValue = wholeNumber + numerator / denominator;

        return [wholeNumber + " " + numerator + "/" + denominator, floatValue.toString()];
      } else {
        // If the input string does not contain a space character, attempt to parse it as a float
        const floatValue = parseFloat(value);
        if (!isNaN(floatValue)) {
          // If parsing is successful, convert the float value to a mixed fraction if FractionView is on

          const wholeNumber = Math.floor(floatValue);
          const fraction = floatValue - wholeNumber;
          if (fraction === 0) {
            return [`${wholeNumber}`, floatValue.toString()];
          }
          const precision = 1e6;
          let numerator = fraction * precision;
          let denominator = precision;
          let gcd = function gcd(a, b) {
            return b ? gcd(b, a % b) : a;
          };
          gcd = gcd(numerator, denominator);
          numerator /= gcd;
          denominator /= gcd;
          return [
            `${wholeNumber} ${Math.floor(numerator)}/${Math.floor(denominator)}`,
            floatValue.toString(),
          ];
        }
      }
    } else if (typeof value === "number") {
      // If the input value is a number, convert it to a mixed fraction if FractionView is on

      const wholeNumber = Math.floor(value);
      const fraction = value - wholeNumber;
      if (fraction === 0) {
        return [`${wholeNumber}`, value.toString()];
      }
      const precision = 1e6;
      let numerator = fraction * precision;
      let denominator = precision;
      let gcd = function gcd(a, b) {
        return b ? gcd(b, a % b) : a;
      };
      gcd = gcd(numerator, denominator);
      numerator /= gcd;
      denominator /= gcd;
      return [
        `${wholeNumber} ${Math.floor(numerator)}/${Math.floor(denominator)}`,
        value.toString(),
      ];
    }
    return "";
  };*/
  const convertToMixedFraction = (value, fractionView = false) => {
    if (typeof value === "string") {
      var [wholeStr, fractionStr] = "";
      // Check if the input string contains a space character to determine if it's a mixed fraction
      if (value.includes("/")) {
        if (value.split(" ").length <= 1) {
          wholeStr = 0;
          fractionStr = value;
        } else {
          [wholeStr, fractionStr] = value.split(" ");
        }

        const wholeNumber = parseInt(wholeStr, 10);
        const [numeratorStr, denominatorStr] = fractionStr.split("/");
        const numerator = parseInt(numeratorStr, 10);
        const denominator = parseInt(denominatorStr, 10);
        // Convert the mixed fraction to a decimal value
        const floatValue = wholeNumber + numerator / denominator;
        // If FractionView is off, return the float value as a string
        if (!fractionView) {
          return floatValue.toString();
        } else {
          return wholeNumber + " " + numerator + "/" + denominator;
        }
      } else {
        // If the input string does not contain a space character, attempt to parse it as a float
        const floatValue = parseFloat(value);
        if (!isNaN(floatValue)) {
          // If parsing is successful, convert the float value to a mixed fraction if FractionView is on
          if (fractionView) {
            const wholeNumber = Math.floor(floatValue);
            const fraction = floatValue - wholeNumber;
            if (fraction === 0) {
              return `${wholeNumber}`;
            }
            const precision = 1e6;
            let numerator = fraction * precision;
            let denominator = precision;
            let gcd = function gcd(a, b) {
              return b ? gcd(b, a % b) : a;
            };
            gcd = gcd(numerator, denominator);
            numerator /= gcd;
            denominator /= gcd;
            return `${wholeNumber} ${Math.floor(numerator)}/${Math.floor(denominator)}`;
          } else {
            // If FractionView is off, return the float value as a string
            return floatValue.toString();
          }
        }
      }
    } else if (typeof value === "number") {
      // If the input value is a number, convert it to a mixed fraction if FractionView is on
      if (fractionView) {
        const wholeNumber = Math.floor(value);
        const fraction = value - wholeNumber;
        if (fraction === 0) {
          return `${wholeNumber}`;
        }
        const precision = 1e6;
        let numerator = fraction * precision;
        let denominator = precision;
        let gcd = function gcd(a, b) {
          return b ? gcd(b, a % b) : a;
        };
        gcd = gcd(numerator, denominator);
        numerator /= gcd;
        denominator /= gcd;
        return `${wholeNumber} ${Math.floor(numerator)}/${Math.floor(denominator)}`;
      } else {
        // If FractionView is off, return the number as a string
        return value.toString();
      }
    }

    // Return an empty string if the input value cannot be converted
    return "";
  };
  const formatCurrency = (value) => {
    const sanitizedValue = value.replace(/[^0-9.,]/g, "");
    return Number(sanitizedValue).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  return (
    <ProjectContext.Provider
      value={{
        addProject,
        projects,
        deleteProjectAndCutlists,
        Vendors,
        addMaterial,
        Materials,
        deleteMaterialandCutLists,
        Cutlists,
        fetchCutlists,
        addCutlist,
        formatCurrency,
        convertToMixedFraction
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired, // Ensure children prop is provided and of type node
};
// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "CURRENT_USER": {
      return { ...state, CurrentUser: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const storedDarkState = localStorage.getItem("Dark") === "true";
  const storedColorState = localStorage.getItem("TabColor") || "info";
  const storedColorMatchState = localStorage.getItem("TabColorMatch") || "#49a3f1";
  const sidenavColorsMatch = ["#EC407A", "#42424a", "#49a3f1", "#66BB6A", "#FFA726", "#EF5350"];
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: storedColorState,
    sidenavColorMatch:storedColorMatchState,
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "subscription",
    CurrentUser: "",
    darkMode: storedDarkState,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setSidenavColorMatch = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setCurrentUser = (dispatch, value) => dispatch({ type: "CURRENT_USER", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setSidenavColorMatch,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setCurrentUser,
  setDirection,
  setLayout,
  setDarkMode,
};
