/*eslint-disable no-unused-vars*/
import Grid from "@mui/material/Grid";

import React, { useState, useEffect,useContext } from "react";
// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Loading from "components/Loading";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/optimization/components/Header";
import { ProjectContext } from "../../context";
// Images

function Overview() {
  const { projects, Cutlists, Materials } = useContext(ProjectContext);
  const [FractionView, setFractionView] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);

  }, [projects]);
  const ToggleViewFraction = () => {
    setFractionView((prev) => !prev);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {loading ? <Loading/>:
      <Header Diedata={projects[1]} Fraction={FractionView} setFraction={ToggleViewFraction} ></Header>}
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
