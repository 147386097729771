import React, { useState, useEffect, useContext } from "react";
// @mui icons
import { useParams } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Loading from "components/Loading";

import { ProjectContext } from "../../context";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "./components/Header";

// Images

function Overview() {
  const [CurrentMaterial, setCurrentMaterial] = useState({});
  const [loading, setLoading] = useState(true);
  const { materialId } = useParams();
  const { Materials } = useContext(ProjectContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Materials) {
          const Materialoptions = Materials.find((project) => project.id == materialId);

          if (Materialoptions) {
            setCurrentMaterial(Materialoptions);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
        setLoading(false);
      }
    };
      fetchData();
  }, [Materials]);

  if (loading) {
    return <Loading />;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header Projectdata={CurrentMaterial}></Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
