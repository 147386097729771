/*eslint-disable no-unused-vars*/
import {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  lazy,
} from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import InfoIcon from "@mui/icons-material/Info";
import { ProjectContext } from "../../../../context";
import PropTypes from "prop-types";
import  { createFilterOptions } from "@mui/material/Autocomplete";
import { Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import MDButton from "components/MDButton";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import MDSnackbar from "components/MDSnackbar";
import "./modalStyles.css";
import { useMaterialUIController } from "../../../../context";

import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Images
const DieShapeForm = lazy(() => import("components/DieShapeForm"));

const InventoryList = lazy(() => import("layouts/Project-Details/components/Inventory"));
const JobStats = lazy(() => import("layouts/Project-Details/components/JobStats"));
function Header({ Projectdata }) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const{deleteProjectAndCutlists} = useContext(ProjectContext);

  const ImageRef = useRef(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const [Abbriviation, setAbbriviation] = useState(Projectdata.Abbriviation || "");


  const [JobName, setJobName] = useState("");
  const [Job_Address1, setJob_Address1] = useState("");
  const [Notes, set_Notes] = useState("");
  const [Job_Num, setJob_Num] = useState("");

  const [Status, setStatus] = useState("");
  const [isDirty, setisDirty] = useState(false);



  const [ModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };


  const [openDialog, setOpenDialog] = useState(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState(null);

  const handleDeleteClick = (projectId) => {
    setProjectIdToDelete(projectId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setProjectIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (projectIdToDelete) {
      await deleteProjectAndCutlists(projectIdToDelete);
      handleCloseDialog(); // Close dialog after deletion
    }
  };






  const SavedAlert = (
    <MDSnackbar
      color="success"
      icon="star"
      title="Saved!"
      content=""
      dateTime="now"
      open={ModalOpen}
      onClose={closeModal}
      close={closeModal}
      bgWhite
    />
  );
  const handleSave = (event) => {
    event.preventDefault();
  
    const SaveData = async () => {

      const auth = getAuth();
      const user = auth.currentUser;
  
      if (!user) {
        console.error("No user is signed in.");
        return;
      }
  
      const userId = user.uid;
  
      const formDataObject = {
        JobName: JobName,
        Abbriviation: Abbriviation,
        Address: Job_Address1,
        Status: Status,
        ProjectNum: Job_Num,
        Notes: Notes,
      };
  
      const projectId = Projectdata.id;
      const projectRef = doc(getFirestore(), "customers", userId, "projects", projectId);
  
      try {
        await updateDoc(projectRef, formDataObject);
  
        setisDirty(false);
        openModal();
      } catch (error) {
        console.error("Error updating Firestore document:", error);
      }
    };
  
    SaveData();
  };
  useEffect(() => {

    setAbbriviation(Projectdata.Abbriviation);
    setJobName(Projectdata.JobName);
    setJob_Address1(Projectdata.Address ? Projectdata.Address : "");
    set_Notes(Projectdata.Notes ? Projectdata.Notes : "");
    setJob_Num(Projectdata.ProjectNum ? Projectdata.ProjectNum : "");
    setStatus(Projectdata.Status);
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
 
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => {
      // Cleanup code here (if needed)
      window.removeEventListener("resize", handleTabsOrientation);
    };
  }, [Projectdata]);

  const handleDirtyChange = () => {
    setisDirty(true);
  };


  const InfoTooltip = ({ title }) => {
    return (
      <Tooltip
        title={title} // Adjust the placement to the right end
      >
        <IconButton>
          <InfoIcon color={darkMode ? "light" : "dark"} />
        </IconButton>
      </Tooltip>
    );
  };
  InfoTooltip.propTypes = {
    title: PropTypes.string.isRequired,
  };

  const children = ({ tabValue }) => {
    let content;
    switch (tabValue) {
      case 0:
        content = (
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  color={darkMode ? "grey-400" : "dark"}
                >
                  General Info
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                ></MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={Job_Address1 || ""}
                    label="Address"
                    onChange={(event) => {
                      setJob_Address1(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                  />
                </MDBox>

                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={Job_Num || ""}
                    onChange={(event) => {
                      setJob_Num(event.target.value);
                      handleDirtyChange();
                    }}
                    type="text"
                    label="Job Number"
                    fullWidth
                  />
                </MDBox>

                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  {/*<Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    disableClearable
                    fullWidth
                    options={Useroptions}
                    value={Waltek_PM}
                    onChange={(event, newValue) => {
                      handleDirtyChange();
                      setWaltek_PM(newValue);
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.key == value.key;
                    }}
                    getOptionLabel={(option) => {
                      // e.g. value selected with enter, right from the input
                      if (typeof option === "string" && Useroptions) {
                        const selectedOption = Useroptions.find((die) => die.key === option);
                        if (selectedOption) {
                          return selectedOption.label;
                        }
                      }
                      if (option.inputValue) {
                        return option.label;
                      }
                      return option.label;
                    }}
                    filterOptions={(options, params) => {
                      return filter(options, params);
                    }}
                    renderOption={(optionProps, option) => (
                      <li key={option.key} {...optionProps}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Project Manager" />}
                  />*/}
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  color={darkMode ? "grey-400" : "dark"}
                >
                  Notes
                </MDBox>

                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Notes || ""}
                      onChange={(event) => {
                        set_Notes(event.target.value);
                        handleDirtyChange();
                      }}
                      label="Notes..."
                      fullWidth
                      multiline
                      rows={5}
                    />
                  </MDBox>
                </MDBox>
                {ModalOpen && SavedAlert }
              </Grid>
              <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
              <MDButton variant="gradient"color="error" onClick={() => handleDeleteClick(Projectdata.id)}>
        Delete Project
      </MDButton>
      </MDBox>
            </Grid>
          </MDBox>
        );
        break;
      case 1:
        content = (
          <div>
            <JobStats JobData={Projectdata} />
          </div>
        );
        break;
      case 2:
        content = <InventoryList />;
        break;
      case 3:
        content = <></>;
        break;
      default:
        content = null;
    }
    return content;
  };
  const handleSetTabValue = async (event, newValue) => {
    setTabValue(newValue);
  };
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    const wrapper = ImageRef.current;
    const rect = wrapper.parentNode.parentNode.getBoundingClientRect();
    const scaleFactor = Math.max(rect.width, rect.height) / 100;
    if (!isClicked) {
      wrapper.style.zIndex = "99";
      wrapper.style.transform = `scale(1)`;
      wrapper.style.borderTopLeftRadius = "";
    } else if (wrapper.textContent != "p" && isClicked) {
      (wrapper.style.borderTopLeftRadius = "0"), (wrapper.style.zIndex = "9999999");
      wrapper.style.backgroundColor = "white";
      wrapper.style.transform = `scale(${scaleFactor})`;
    }
  }, [isClicked]);
  const handleClick = (event) => {
    setIsClicked(true);
  };
  const handleblur = (event) => {
    setIsClicked(false);
  };


  useLayoutEffect(() => {
    setisDirty(false);

    return () => {
      // Clean-up code (if needed)
    };
  }, []);
  const createAbbreviation = (input) => {
    // Split the input string into words based on spaces
    const words = input.split(" ");

    // Map each word to its first character, convert it to uppercase, and join them to create the abbreviation
    const abbreviation = words
      .map((word) => {
        // Get the first character of the word
        const firstCharacter = word.charAt(0);

        // Convert the first character to uppercase
        return firstCharacter.toUpperCase();
      })
      .join("");

    return abbreviation;
  };
  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="5rem"
        borderRadius="xl"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "25%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={
                `https://ui-avatars.com/api/?background=2f8de9&color=fff&name=` +
                JobName +
                `&rounded=true&bold=true&font-size=0.33&length=4`
              }
              alt="profile-image"
              size="xl"
              shadow="sm"
              ref={ImageRef}
              className={isClicked ? "project-clicked" : ""}
              onClick={handleClick}
              onMouseLeave={handleblur}
              style={{
                transition: "transform 0.3s ease",
                cursor: "pointer",
                transformOrigin: "left calc(25% + 0px)",
                zIndex: "99",
              }}
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDInput
                fontWeight="medium"
                value={JobName || ""}
                type="text"
                label="Project Name"
                onChange={(event) => {
                  setJobName(event.target.value);
                  handleDirtyChange();
                }}
                sx={{ mt: 2 }}

              />
              <MDBox>
                <MDInput
                  fontWeight="medium"
                  value={Abbriviation || ""}
                  type="text"
                  label="Abbrivation"
                  onChange={(event) => {
                    setAbbriviation(event.target.value);
                    handleDirtyChange();
                  }}
                  sx={{ mt: 2 }}
                />
                <MDButton
                  variant="gradient"
                  color="secondary"
                  title="Auto Abbreviate"
                  onClick={() => {
                    ///get the first letter and the first character after each space of a varible to create an abrriviation
                    setAbbriviation(createAbbreviation(JobName));
                  }}
                  sx={{ mt: "16px" }}
                >
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    sync
                  </Icon>
                </MDButton>
               
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="General Info"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
                <Tab
                  label="Stats"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      format_list_numbered_icon
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>


          <Grid item>
            <MDButton variant="gradient" color="info" onClick={handleSave}>
              <Icon sx={{ fontWeight: "bold" }}>save</Icon>
              Save
            </MDButton>
          </Grid>
        </Grid>
        {children({ tabValue })}
      </Card>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this project and its associated cutlists? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseDialog} color="primary">
            Cancel
          </MDButton>
          <MDButton onClick={handleConfirmDelete} color="error">
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
  Projectdata: {},
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  Projectdata: PropTypes.object,
};
//// linear cutting below

export default Header;
