// @mui material components
import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import XMLParser from "react-xml-parser";
// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import MDButton from "components/MDButton";

function Billing() {
  const [Outdata, setData] = useState([]);

  const exportToPDF = (xmlData) => {
    const doc = new jsPDF({
      orientation: "landscape",
      format: "ledger",
    });

    const parseXMLData = (xmlData) => {
      return xmlData.map((item) => {
        // Handle top-level item (non-nested) properties
        const parsedItem = {
          name: item.name,
          value: item.value || "", // Default to an empty string if no value exists
        };

        // If the item has a "Part" or "Sheet" child, parse them recursively
        if (item.name == "Parts") {
          parsedItem.Parts = item.children.map((part) => {
            return {
              ID: part.children.find((child) => child.name === "ID")?.value || "",
              Area: part.children.find((child) => child.name === "Area")?.value || "",
              NumNestedOnSheet:
                part.children.find((child) => child.name === "NumNestedOnSheet")?.value || "",
              TotalNested: part.children.find((child) => child.name === "TotalNested")?.value || "",
              NumRequested:
                part.children.find((child) => child.name === "NumRequested")?.value || "",
              JpgFullName: part.children.find((child) => child.name === "JpgFullName")?.value || "",
              JpgName: part.children.find((child) => child.name === "JpgName")?.value || "",
            };
          });
        }

        if (item.name == "Sheets") {
          parsedItem.Sheets = item.children.map((sheet) => {
            return {
              SheetName: sheet.children.find((child) => child.name === "SheetName")?.value || "",
              Area: sheet.children.find((child) => child.name === "Area")?.value || "",
              NestedArea: sheet.children.find((child) => child.name === "NestedArea")?.value || "",
              RemnantArea:
                sheet.children.find((child) => child.name === "RemnantArea")?.value || "",
              Yield: sheet.children.find((child) => child.name === "Yield")?.value || "",
              YieldPercent:
                sheet.children.find((child) => child.name === "YieldPercent")?.value || "",
              NumNested: sheet.children.find((child) => child.name === "NumNested")?.value || "",
              JpgFullName:
                sheet.children.find((child) => child.name === "JpgFullName")?.value || "",
              JpgName: sheet.children.find((child) => child.name === "JpgName")?.value || "",
              Parts: sheet.children
                .filter((child) => child.name === "Parts")
                .map((part) => {
                  return part.children.map((p) => {
                    return {
                      ID: p.children.find((child) => child.name === "ID")?.value || "",
                      Area: p.children.find((child) => child.name === "Area")?.value || "",
                      NumNestedOnSheet:
                        p.children.find((child) => child.name === "NumNestedOnSheet")?.value || "",
                      TotalNested:
                        p.children.find((child) => child.name === "TotalNested")?.value || "",
                      NumRequested:
                        p.children.find((child) => child.name === "NumRequested")?.value || "",
                      JpgFullName:
                        p.children.find((child) => child.name === "JpgFullName")?.value || "",
                      JpgName: p.children.find((child) => child.name === "JpgName")?.value || "",
                    };
                  });
                }),
            };
          });
        }

        return parsedItem;
      });
    };
    const parsedData = parseXMLData(xmlData);

    // Generate table headers dynamically from keys of the first item
    const headers = parsedData.length
      ? Object.keys(parsedData[0]).map((key) => ({
          header: key.replace(/_/g, " "), // Replace underscores with spaces for readability
          dataKey: key,
        }))
      : [];

    // General Information Section
    const generalInfo = [
      { id: "Report Title:", name: "Billing Report" },
      { id: "Generated On:", name: new Date().toLocaleDateString() },
    ];

    // Add General Information
    doc.autoTable({
      head: [["ID", "Name"]],
      body: generalInfo.map((row) => [row.id, row.name]),
      margin: { top: 20, left: 10 },
      theme: "striped",
      styles: { fontSize: 10 },
    });

    // Add XML Data Table
    if (parsedData.length > 0) {
      doc.autoTable({
        head: [headers.map((h) => h.header)],
        body: parsedData.map((row) =>
          headers.map((h) => {
            const cellValue = row[h.dataKey]; // Access property directly
            return cellValue !== undefined ? cellValue : ""; // Handle undefined gracefully
          })
        ),
        margin: { top: 10 },
        columnStyles: {
          comments: { cellWidth: 50 },
        },
      });
    } else {
      doc.text("No data available to display.", 20, 50);
    }

    const partsData = parsedData.find((item) => item.name === "Parts");
    const Partsheaders = Object.keys(partsData.Parts[0]).map((key) => ({
      header: key.replace(/_/g, " "), // Replace underscores with spaces for readability
      dataKey: key,
    }));
    if (partsData && Array.isArray(partsData.Parts)) {
      doc.autoTable({
        head: [Partsheaders.map((h) => h.header)],
        body: partsData.Parts.map((row) =>
          Partsheaders.map((h) => {
            const cellValue = row[h.dataKey]; // Access property directly
            return cellValue !== undefined ? cellValue : ""; // Handle undefined gracefully
          })
        ),
        margin: { top: 10 },
        columnStyles: {
          comments: { cellWidth: 50 },
        },
      });
    } else {
      doc.text("No Parts data available to display.", 20, 70);
    }

    const SheetsData = parsedData.find((item) => item.name === "Sheets");

    if (SheetsData && Array.isArray(SheetsData.Sheets)) {
      SheetsData.Sheets.forEach((row, index) => {
        if (index > 0) {
          doc.addPage(); // Add a new page for each subsequent row
        }

        // Page header
        doc.setFontSize(10);
        doc.text(`SheetName: ${row.SheetName}`, 20, 10);
        doc.text(`Area: ${row.Area}`, 20, 15);
        doc.text(`NestedArea: ${row.NestedArea}`, 20, 20);
        doc.text(`RemnantArea: ${row.RemnantArea}`, 20, 25);
        doc.text(`Yield: ${row.Yield}`, 20, 30);
        doc.text(`YieldPercent: ${row.YieldPercent}`, 20, 35);
        doc.text(`NumNested: ${row.NumNested}`, 20, 40);
        doc.text(`JpgName: ${row.JpgName}`, 20, 45);
    
        // Display the image (make sure the path is correct and accessible)
        const imagePath = "../images/"+row.JpgName; // Assuming JpgFullName holds the absolute path to the image
        doc.addImage(imagePath, "JPEG", 110, 20, 200, 75); // Adjust position and size as needed

        // Add parts table
        doc.setFontSize(12);
        doc.text("Parts List:", 20, 230); // Title for parts list

        // Assuming 'Parts' is an array of objects
        const parts = row.Parts; // This is expected to be an array of objects

        // Define the headers for the parts table
        const partHeaders = [
          "Image",
          "Part Area",
          "Part Tag",
          "Qty On Sheet",
          "Total Requested",
          "Total Nested",
        ]; // Adjust these based on the part object structure
        const partData = parts[0].map((part) => [
          part.JpgName || "",
          part.Area || "",
          part.ID || "",
          part.NumNestedOnSheet || "",
          part.NumRequested|| "",
          part.TotalNested || "",
        ]);

            // Create the table
    doc.autoTable({
      head: [partHeaders],
      body: partData,
      startY: 100, // Adjust as needed
      margin: { left: 20 },
      styles: { fontSize: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40 }, // Adjust for the image column width
      },
      didDrawCell: (data) => {

        if (data.column.index === 0 && data.cell.raw !== "" && data.section !== "head") {  // Check if it's the image column
          const imgPath = "../images/"+row.Parts[0][data.row.index].JpgName;  // Assuming Image contains path/URL

          //debugger;//eslint-disable-line no-debugger
          if (imgPath) {
            // Add the image into the cell
            const imgWidth =10; // Adjust the image width
            const imgHeight = 10; // Adjust the image height
            const cellX = data.cell.x + 2; // X-position inside the cell (padding)
            data.cell.text[0] = ""; 
            const cellY = data.cell.y + 2; // Y-position inside the cell (padding)
            doc.addImage(imgPath, 'JPEG', cellX, cellY, imgWidth, imgHeight);
          }
        }
      }
    });
  });
    } else {
      doc.text("No Sheets data available to display.", 20, 70);
    }
    // Add Footer with Page Numbers
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width - 30,
        doc.internal.pageSize.height - 10
      );
    }

    doc.save("BillingReport.pdf");
  };
  useEffect(() => {
    fetch("/nest_report.xml")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch XML file");
        }
        return res.text();
      })
      .then((data) => {
        const xml = new XMLParser().parseFromString(data);
        const items = xml.children; // Assuming top-level children represent the data you want
        setData(items);
      })
      .catch((err) => console.error("Error loading XML:", err));
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                  <MasterCard number={4562112245947852} holder="jack peterson" expires="11/22" />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <MDButton
                    onClick={() => {
                      exportToPDF(Outdata);
                    }}
                  >
                    Print Data
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    icon="paypal"
                    title="paypal"
                    description="Freelance Payment"
                    value="$455.00"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PaymentMethod />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Invoices />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <BillingInformation />
            </Grid>
            <Grid item xs={12} md={5}>
              <Transactions />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
