import Dashboard from "layouts/dashboard";
import DOptimze from "layouts/1doptimizer";
import Projects from "layouts/Projects";
import Materials from "layouts/Materials";
import MaterialsDetails from "layouts/MaterialsDetails";
import ProjectDetails from "layouts/Project-Details";
//import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Account from "layouts/profile";
import Optimize from "layouts/optimization";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPass from "layouts/authentication/reset-password";

import Marketing from "layouts/Marketing";
// @mui icons
import Icon from "@mui/material/Icon";
//import { Collapse } from "@mui/material";
const Homepage = "";
const routes = [
  {
    type: "collapse",
    name: "1D Optimizer",
    key: "optimizer",
    icon: <Icon fontSize="small">content_cut</Icon>,
    route: Homepage + "/optimizer",
    display: true,
    access:1,
    component: <DOptimze />,
  },
  {
    type: "collapse",
    name: "Optimize",
    key: "Optimize",
    icon: <Icon fontSize="small">cut</Icon>,
    route: Homepage + "/optimizer/:optimizeId",
    display: false,
    access:1,
    component: <Optimize />,
  },
  {
    type: "collapse",
    name: "Sales",
    key: "sales",
    icon: <Icon fontSize="small">content_cut</Icon>,
    route: Homepage + "/Home",
    display: false,
    component: <Marketing />,
  },
  {
    type: "collapse",
    name: "My Account",
    key: "Account",
    icon: <Icon fontSize="small">person</Icon>,
    route: Homepage + "/Account",
    display: true,
    component: <Account />,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    icon: <Icon fontSize="small">holiday_village</Icon>,
    route: Homepage + "/Projects",
    access:1,
    display: true,
    component: <Projects />,
  },
  {
    type: "collapse",
    name: "Project Details",
    key: "project_details",
    icon: <Icon fontSize="small">holiday_village</Icon>,
    route: Homepage + "Projects/:projectId",
    display: false,
    component: <ProjectDetails />,
  },
  {
    type: "collapse",
    name: "Material",
    key: "material",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    route: Homepage + "/Material",
    access:1,
    display: true,
    component: <Materials />,
  },
  {
    type: "collapse",
    name: "MaterialDetails",
    key: "materialDetails",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    route: Homepage + "/Material/:materialId",
    access:1,
    display: false,
    component: <MaterialsDetails />,
  },

  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: Homepage + "/authentication/sign-in",
    display: false,
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">login</Icon>,
    route: Homepage + "/authentication/reset-password",
    display: false,
    component: <ResetPass />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: Homepage + "/notifications",
    display: false,
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: Homepage + "/authentication/sign-up",
    display: false,
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "My Subscription",
    key: "subscription",
    icon: <Icon fontSize="small">card_membership</Icon>,
    route: Homepage + "/subscription",
    display: true,
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "templates",
    key: "templates",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: Homepage + "/templates",
    display: true,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "billing",
    key: "billing",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: Homepage + "/billing",
    display: true,
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "Notifications",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: Homepage + "/Notifications",
    display: true,
    component: <Notifications />,
  },
];

export default routes;
