/*eslint-disable no-unused-vars*/
import React, { useState, useEffect, useContext } from "react";
import { ProjectContext, AuthContext } from "../../context";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  useGridApiRef,
  GridActionsCellItem,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";

const Tables = () => {
  const apiRef = useGridApiRef();
  const { projects, addProject } = useContext(ProjectContext);
  const { AppcurrentUser } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [PMs, setPMs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (projects) {
      const initialRows = projects
        .map((project, index) => {
          const {
            JobName = "N/A", // Default to "N/A" if JobName is missing
            Abbriviation = "N/A",
            Address = "N/A",
            userId = "Unknown",
            Status = "Unknown",
            ProjectNum = '',
            id = index + 1, // Use index as fallback for id
          } = project;
          console.log(project);
          // Handle any other fields that need defaults
          const EmpName = project?.userId || "Error";

          return {
            id, // Ensure ID is unique and defaults to the current index
            JobName,
            Abbriviation,
            Address,
            ProjectNum,
            Status,
          };
        })
        .sort((a, b) => {
          if (a.Status < b.Status) {
            return -1;
          } else if (a.Status > b.Status) {
            return 1;
          } else {
            return a.JobName.localeCompare(b.JobName);
          }
        });
  
      setRows(initialRows);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [projects]);

  const columns = [
    {
      field: "Status",
      headerName: "Open/Edit",
      flex: 0.25,
      renderCell: (params) => {
        return (
          <MDTypography component={Link} to={`/Projects/${params.row.id}`}>
            <MDBadge
              badgeContent={"Open"}
              size="sm"
              container
              circular
              color={params.value == "Active" ? "error" : "success"}
            />
          </MDTypography>
        );
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Open/Edit
        </MDTypography>
      ),
    },
    {
      field: "JobName",
      headerName: "Job Name",
      flex: 1,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.JobName}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Job Name
        </MDTypography>
      ),
    },
    {
      field: "Abbriviation",
      headerName: "Abbriviation",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.Abbriviation}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Abbriviation
        </MDTypography>
      ),
    },
    {
      field: "Address",
      headerName: "Job Location",
      flex: 0.5,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.Address}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Job Location
        </MDTypography>
      ),
    },
    {
      field: "ProjectNum",
      headerName: "ProjectNum",
      flex: 0.5,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.ProjectNum}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Project Num
        </MDTypography>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" sx={{ fontSize: "24px" }} color="white">
                      Project List
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      onClick={() => addProject({userId:AppcurrentUser.uid,JobName :"New",
                        Abbriviation:"NEW",
                        Job_Address2:"",
                        Status : "Active"})}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;Add New Project
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <LinearProgress />
                ) : (
                  <DataGrid
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    autoHeight
                    moreActionsIcon
                    disableRowSelectionOnClick
                    loading={rows.length === 0}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Tables;
