import { Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { AuthContext } from "context";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

function Cover() {
  const [email, setEmail] = useState("");
  const [Name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [Terms, setTerms] = useState(false);
  const { handleSignUp } = useContext(AuthContext);

  useEffect(() => {
    const errors = {};

    // Name validation: at least 6 characters and alphanumeric
    if (!/^(?! )[a-zA-Z0-9 ]{6,}(?<! )$/.test(Name)) {
      errors.Name = "Name must be more than 6 characters and alphanumeric with no spaces at the beginning or end.";
    }

    // Email validation: must be a valid email without plus addressing
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      errors.email = "Invalid email format.";
    } else if (email.includes("+")) {
      errors.email = "Plus addressing is not permitted.";
    }

    // Password validation: 8 or more characters, with at least 2 numbers and 2 special characters
    if (!/^(?=.*\d{2,})(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]{2,}).{8,}$/.test(password)) {
      errors.password =
        "Password must be 8+ characters, with at least 2 numbers and 2 special characters.";
    }

    // Terms validation: must be true
    if (!Terms) {
      errors.Terms = "You must accept the terms and conditions to sign up.";
    }

    setValidationErrors(errors);
  }, [email, Name, password, Terms]);
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Name"
                onChange={(e) => setName(e.target.value)}
                variant="standard"
                fullWidth
                error={!!validationErrors.Name} 
                helperText={validationErrors.Name || ""}
              />

            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                variant="standard"
                fullWidth
                error={!!validationErrors.email} 
                helperText={validationErrors.email || ""}
              />

            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                variant="standard"
                fullWidth
                error={!!validationErrors.password} 
                helperText={validationErrors.password || ""}
              />
 
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox sx={{color:"red"}} onChange={(e) => setTerms(e.target.checked)} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
          
            </MDBox>
            {validationErrors.Terms &&  <MDTypography
                component="p"
                color="error"
                fontSize="10px"
                textGradient
              >
                {validationErrors.Terms}
              </MDTypography>}
            <MDBox mt={4} mb={1}>
              {Object.keys(validationErrors).length !== 0 ? (
                ""
              ) : (
                <MDButton
                  variant="gradient"
                  onClick={() => handleSignUp(email, password, Name)}
                  color="info"
                  fullWidth
                >
                  sign Up
                </MDButton>
              )}
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
