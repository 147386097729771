import React, { useState, useEffect, useContext } from "react";
// @mui icons
import { useParams } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Loading from "components/Loading";

import { ProjectContext } from "../../context";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/Project-Details/components/Header";

// Images

function Overview() {
  const [CurrentProject, setCurrentProject] = useState({});
  const [loading, setLoading] = useState(true);
  const { projectId } = useParams();
  const { projects } = useContext(ProjectContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projects) {
          const Joboptions = projects.find((project) => project.id == projectId);

          if (Joboptions) {
            setCurrentProject(Joboptions);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
        setLoading(false);
      }
    };

      fetchData();
    
  }, [projects]);

  if (loading) {
    return <Loading />;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header Projectdata={CurrentProject}></Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
