import Card from "@mui/material/Card";
import React, { useState, useContext, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { AuthContext } from "context";
import { sendPasswordResetEmail } from "firebase/auth";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

function Cover() {
  const { authType } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isCooldown, setIsCooldown] = useState(false);
  const [timeLeft, setTimeLeft] = useState(1);
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(authType, email);
      setMessage("If an account exists, a password reset email was sent! Please check your inbox.");
      setError("");

      // Start cooldown for 5 minutes (300 seconds)
      setIsCooldown(true);
      setTimeLeft(300);

      // Store cooldown state and timeLeft in localStorage
      const expiryTime = Date.now() + 120000; // Current time + 5 minutes in ms
      localStorage.setItem("cooldown", JSON.stringify({ isCooldown: true, expiryTime }));
    } catch (err) {
      setError("Error sending password reset email. Please try again.");
      setMessage("");
    }
  };

  useEffect(() => {
    // Check if cooldown data exists in localStorage
    const storedCooldown = localStorage.getItem("cooldown");
    if (storedCooldown) {
      const { isCooldown, expiryTime } = JSON.parse(storedCooldown);
      if (isCooldown) {
        const remainingTime = Math.max(0, Math.floor((expiryTime - Date.now()) / 1000));
        if (remainingTime > 0) {
          setIsCooldown(true);
          setTimeLeft(remainingTime);
        } else {
          // Reset if cooldown expired
          localStorage.removeItem("cooldown");
        }
      }
    }
  }, []);

  useEffect(() => {
    let interval;
    const storedCooldown = localStorage.getItem("cooldown");
    if (storedCooldown) {
      const { isCooldown, expiryTime } = JSON.parse(storedCooldown);
      if (isCooldown) {
        const remainingTime = Math.max(0, Math.floor((expiryTime - Date.now()) / 1000));
        if (remainingTime > 0) {
          setIsCooldown(true);
          interval = setInterval(() => {
            setTimeLeft((prev) => prev - 1);
          }, 1000);
        } else {
          // Reset if cooldown expired
          setIsCooldown(false);
          localStorage.removeItem("cooldown");
        }
      }
    }
    return () => clearInterval(interval);
  }, [isCooldown, timeLeft]);

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in the Next 5 Minutes
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                error={!isValidEmail(email) && email !== ""}
                helperText={
                  !isValidEmail(email) && email !== "" ? "Please enter a valid email." : ""
                }
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                onClick={handleSubmit}
                variant="gradient"
                color="info"
                fullWidth
                disabled={!isValidEmail(email) || isCooldown} // Disable if invalid email or on cooldown
              >
                {isCooldown ? `Try again in ${timeLeft} sec` : "Reset"}
              </MDButton>
            </MDBox>
          </MDBox>
          {message && <p>{message}</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
