/*eslint-disable no-unused-vars*/
import {
  useState,
  useEffect,
  useContext,
  useRef,
  lazy,
} from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {  FormControl, TextField } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import MDButton from "components/MDButton";
import Tab from "@mui/material/Tab";
import { useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 React base styless
import breakpoints from "assets/theme/base/breakpoints";
import MDSnackbar from "components/MDSnackbar";
import "./modalStyles.css";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useMaterialUIController, ProjectContext, AuthContext } from "../../../../context";

// Images
const DieShapeForm = lazy(() => import("components/DieShapeForm"));
const CuttingResults = lazy(() => import("layouts/optimization/components/CuttingResults"));
const PartList = lazy(() => import("layouts/optimization/components/Parts"));
function Header({ Diedata,Fraction, setFraction }) {
  const [controller, dispatch] = useMaterialUIController();
  const { optimizeId } = useParams();
  const { projects, Cutlists, Materials, addMaterial, convertToMixedFraction } = useContext(ProjectContext);///addMaterial
  const { Subscription } = useContext(AuthContext);///addMaterial
  const { darkMode } = controller;
  const [DarkString, setDarkString] = useState("");
  const wrapperRef = useRef(null);
  const ImageRef = useRef(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [initialState, setInitialState] = useState({});
  const [DieName, setDieName] = useState("");
  const [FormDate, setFormDate] = useState("");
  const [ImageURL, setImageURL] = useState("");
  const [DieDesc, setDieDesc] = useState("");
  const [isDirty, setisDirty] = useState(false);
  const [DieProject, setDieProject] = useState("");
  const [DieWeight, setDieWeight] = useState(""); //
  const [results, setresults] = useState('{"Results": ""}'); //
  const [Parts, setPartsList] = useState({});
  const [StockLength, setStockLength] = useState(120);
  const [StockWeight, setStockWeight] = useState(0); //
  const [OverMeasure, setOverMeasure] = useState(0); //
  const [OptLength, setOptLength] = useState(0); //
  const [usefulScrap, setusefulScrap] = useState(0); //
  const [Iterations, setIterations] = useState(100); //
  const [TotalParts, setTotalParts] = useState(0); //
  const [Generations, setGenerations] = useState(100); //
  const [Crossover, setCrossover] = useState(0.8); //
  const [Mutation, setMutation] = useState(0.3); //
  const [Randomness, setRandomness] = useState(0.3); //
  const [SawKerf, setSawKerf] = useState(0.1875); //
  const [MaterialFinish, setMaterialFinish] = useState(0); //
  const [FabOrder, setFabOrder] = useState(0); //
  const [JobOptions, setJobOptions] = useState([]); //
  const filter = createFilterOptions();

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [OptLockSB, setOptLock] = useState(false);
  const [ModalData, setModalData] = useState();


  useEffect(() => {
    // Compare current state with the initial state to set isDirty
    const isChanged =
      SawKerf !== initialState.SawKerf ||
      StockLength !== initialState.StockLength ||
      OverMeasure !== initialState.OverMeasure ||
      usefulScrap !== initialState.UsefulScrap ||
      Iterations !== initialState.Iterations ||
      Generations !== initialState.Generations ||
      Crossover !== initialState.Crossover ||
      Randomness !== initialState.Randomness ||
      Mutation !== initialState.Mutation ||
      DieName !== initialState.DieName ||
      DieDesc !== initialState.DieDesc ||
      JSON.stringify(Parts) !== JSON.stringify(initialState.Parts) ||
      DieProject !== initialState.DieProject ||
      DieWeight !== initialState.DieWeight ||
      results !== initialState.Results ||
      MaterialFinish !== initialState.MaterialFinish ||
      FabOrder !== initialState.FabOrder ||
      FormDate !== initialState.FormDate;
  
    setisDirty(isChanged);
  }, [
    SawKerf,
    StockLength,
    OverMeasure,
    usefulScrap,
    Iterations,
    Generations,
    Crossover,
    Randomness,
    Mutation,
    DieName,
    DieDesc,
    Parts,
    DieProject,
    DieWeight,
    results,
    MaterialFinish,
    FabOrder,
    FormDate,initialState// To ensure we compare against the latest initial state
  ]);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openOptLockSB = () => setOptLock(true);
  const closeOptLockSB = () => setOptLock(false);
  const [scaleFactor, setScaleFactor] = useState(1);

  const [gridRef, setgridRef] = useState({});
  const [resultLock, setresultLock] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const SavedAlert = (
    <MDSnackbar
      color="success"
      icon="star"
      title="Saved!"
      content=""
      dateTime="now"
      open={ModalOpen}
      onClose={closeModal}
      close={closeModal}
      bgWhite
    />
  );
  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Advanced Mode Activated"
      content="The default settings work well for a vast majority of situations, it you are having trouble with optimizing time or low yeild results. Adjusting these values may help."
      dateTime="now"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );
  
  const renderOptLockSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Cutlist Locked"
      content="Clear optimization to edit locked values."
      dateTime="now"
      open={OptLockSB}
      onClose={closeOptLockSB}
      close={closeOptLockSB}
      bgWhite
    />
  );
  const syncDesc = async () => {
    const newEntry = Materials.find((die) => die.materialName === DieName);
    const newDesc = newEntry ? newEntry.materialDesc : DieDesc;
    console.log(newEntry);
    setDieDesc(newDesc.replace(/"/g, '"'));
    setImageURL(newEntry?.Image);
  };
  const handleClearOpt = (event) => {
    event.preventDefault();
    setresults('{"Results": ""}');
    if (tabValue == 2) {
      setTabValue(1);
    }
    //change tab if tab is cutting
  };
  useEffect(() => {
    setresultLock(results != '{"Results": ""}');
  }, [results]);
  useEffect(() => {
    setDarkString(darkMode ? "dark" : "light");
  }, [darkMode]);

  const handleSave = (event) => {
    event.preventDefault();

    const SaveData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        console.error("No user is signed in.");
        return;
      }
      const userId = user.uid;
      //debugger;//eslint-disable-line no-debugger
      const formDataObject = {
        Description: DieDesc,
        Die: DieName,
        Finish: MaterialFinish,
        Material: "",
        Order: "",
        Fraction,
        Parts: JSON.stringify(Parts),
        Project: DieProject,
        Results: results,
        status: "",
        yieldr: "",
        SawKerf: SawKerf,
        StockLength,
        OverMeasure,
        usefulScrap,
        Iterations,
        Generations,
        Crossover,
        Mutation,
        Randomness,
        DieWeight: DieWeight,
      };


      const projectRef = doc(getFirestore(), "customers", userId, "Cutlists", optimizeId);
      try {
        await updateDoc(projectRef, formDataObject);
        setisDirty(false);
        openModal();
      } catch (error) {
        console.error("Error updating Firestore document:", error);
      }
    };

    SaveData();
  };
  useEffect(() => {
    if(Parts.length){
  const Total = Parts?.reduce((acc, item) => {
    return acc + parseInt(item[1], 10);
  }, 0);
        console.log(Parts);
        setTotalParts(Total);}
      }, [Parts]);
  useEffect(() => {

    let Diedata2 = Cutlists.find((item) => {
      return item?.id == optimizeId;
    });
    if (Diedata2) {
     const SawKerf= Diedata2.SawKerf || "0";
     const StockLength= Diedata2.StockLength  || 120;
     const OverMeasure= Diedata2.OverMeasure  || "";
     const UsefulScrap= Diedata2.usefulScrap  || "";
     const Iterations= Diedata2.Iterations  || "";
     const Generations= Diedata2.Generations  || 100;
     const Crossover= Diedata2.Crossover  || "";
     const Randomness= Diedata2.Randomness  || "";
     const Fraction= Diedata2.Fraction ;
     const Mutation= Diedata2.Mutation  || "";
     const DieName= Diedata2.Die  || "";
     const DieDesc= Diedata2.Description || "";
     const Parts= JSON.parse(Diedata2.Parts)  || [];
     const DieProject= Diedata2.Project  || "";
     const DieWeight= Diedata2.Material  || 0;
     const Results= Diedata2.Results  || '{"Results"= ""}';
     const MaterialFinish= Diedata2.Finish  || "";
     const FabOrder= Diedata2.id || "";
     const FormDate= Diedata2.createdAt  || "";
      setInitialState({
        SawKerf: SawKerf,
        StockLength: StockLength,
        OverMeasure: OverMeasure,
        UsefulScrap: UsefulScrap,
        Iterations: Iterations,
        Generations: Generations,
        Crossover: Crossover ,
        Randomness: Randomness,
        Fraction: Fraction ,
        Mutation: Mutation,
        DieName: DieName,
        DieDesc: DieDesc,
        Parts: Parts,
        DieProject: DieProject,
        DieWeight: DieWeight,
        Results: Results,
        MaterialFinish: MaterialFinish,
        FabOrder: FabOrder,
        FormDate: FormDate,
      });
      setSawKerf(SawKerf);
      setStockLength(StockLength );
      setOverMeasure(OverMeasure);
      setusefulScrap(usefulScrap);
      setIterations(Iterations );
      setGenerations(Generations);
      //sum total parts here 
const Total = Parts.reduce((acc, item) => {
  return acc + parseInt(item[1], 10);
}, 0);
      console.log(Parts);
      setTotalParts(Total);
      setCrossover(Crossover );
      setRandomness(Randomness);
      setMutation(Mutation);
      setDieName(DieName);
      setDieDesc(DieDesc);
      setPartsList(Parts);
      setDieProject(DieProject);
      const newEntry = Materials.find((die) => die.materialName === DieName);
      setImageURL(newEntry?.Image || "")
      setDieWeight(newEntry?.Material || "");
      setresults(Results);
      setMaterialFinish(MaterialFinish);
      setFabOrder(FabOrder);
      setFormDate(FormDate);
      setIsAdvancedOpen(false);
      if (Results != '{"Results": ""}') {
        setresultLock(true);
      }
  
      window.addEventListener("resize", handleTabsOrientation);

      // Call the handleTabsOrientation function to set the state with the initial value.
      handleTabsOrientation();
      // Remove event listener on cleanup
      return () => {
        // Cleanup code here (if needed)
        window.removeEventListener("resize", handleTabsOrientation);
      };
    }
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    setisDirty(false);
  }, [optimizeId,Diedata]);

  const toggleAdvanced = () => {
    setIsAdvancedOpen((prev) => !prev);
    setWarningSB(!isAdvancedOpen);
  };
  const csvToArr = (stringVal, splitter) => {
    const [keys, ...rest] = stringVal
      .trim()
      .split("\n")
      .map((item) => item.replace(/\r/g, "").split(splitter));

    return rest;
  };

  const updateparts = (newValue) => {
    var temp = csvToArr(newValue, ",");
    setPartsList(temp);
  };


  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      if (isAdvancedOpen) {
        wrapper.classList.add("show");
      } else {
        wrapper.classList.remove("show");
      }
    }
  }, [isAdvancedOpen]);


  useEffect(() => {
    const newEntry = Materials.find((die) => die.DieNum === DieName);
    const newWeight = newEntry ? newEntry.Est_Weight : 0;
    //const newDesc = newEntry ? newEntry.Application : DieDesc;
    setDieWeight(newWeight);
  }, [DieName, Materials]);

  useEffect(() => {
    setStockWeight((DieWeight * (StockLength / 12)).toFixed(2) + " lbs");
  }, [DieWeight, StockLength]);
  useEffect(() => {
    const newWeightEntry = Materials.find((die) => die.DieNum === DieName);
    const newWeight = newWeightEntry ? newWeightEntry.Est_Weight : 0;
    setDieWeight(newWeight);
  }, [DieProject]);

    useEffect(() => {
      const Joboptions = Object.entries(projects)
      .map((project) => ({
        key: project[1].id,
        label: project[1].JobName,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
      setJobOptions(Joboptions);

    }, [projects]);

  useEffect(() => {
    setOptLength(StockLength - OverMeasure);
  }, [StockLength, OverMeasure]);

  const ModalSubmit = (data) => {
    setModalData(data);
  };

  const InfoTooltip = ({ title }) => {
    return (
      <Tooltip
        title={title} // Adjust the placement to the right end
      >
        <IconButton>
          <InfoIcon color={darkMode ? "light" : "dark"} />
        </IconButton>
      </Tooltip>
    );
  };
  InfoTooltip.propTypes = {
    title: PropTypes.string.isRequired,
  };

  const Dieoptions = Materials.map((die) => ({
    key: die?.id,
    title: die.materialName,
    label: die.materialName,
  })).sort((a, b) => a.label.localeCompare(b.label));

  var AuthorNamehelp = "temp fix later"; // Find the entry where the EmployeeU matches Author
  var tabinsert = 0;
  if (tabValue == 2 && results == '{"Results": ""}') {
    tabinsert = 1;
  } else {
    tabinsert = tabValue;
  }
  const children = ({ tabValue }) => {
    let content;
    switch (tabValue) {
      case 0:
        content = (
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  color={darkMode ? "grey-400" : "dark"}
                >
                  General Info
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <FormControl sx={{ minWidth: "100%" }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      disableClearable
                      options={JobOptions || []}
                      value={
                        JobOptions.length > 0
                          ? JobOptions.find((option) => option.key === DieProject) || null
                          : null
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setDieProject(newValue.key); // Ensure `newValue.key` exists
                  
                        }
                      }}
                      renderInput={(params) => <TextField {...params} label="Project" />}
                    />
                  </FormControl>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={DieWeight}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    label="Die Weight Per Foot"
                    onChange={(event) => {
                      setDieWeight(event.target.value);
                  
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={StockWeight || 0}
                    type="text"
                    label="Stock Weight"
                    fullWidth
                    disabled
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={MaterialFinish || ""}
                    type="text"
                    label="Material Finish"
                    onChange={(event) => {
                      setMaterialFinish(event.target.value);
             
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={AuthorNamehelp || ""}
                    type="text"
                    label="Author"
                    fullWidth
                    readOnly
                    InputLabelProps={{ shrink: true }}
                  />
                </MDBox>
  
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  color={darkMode ? "grey-400" : "dark"}
                >
                  Material Settings
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={() => {
                    if (resultLock) {
                      handleShake();
                    }
                  }}
                  disabled="disabled"
                >
                  <MDInput
                    fontWeight="medium"
                    value={StockLength}
                    type="number"
                    label={
                      <div>
                        <InfoTooltip title="Total Order Length of material." />
                        {"Stock Order Length"}
                      </div>
                    }
                    onChange={(event) => {
                      setStockLength(event.target.value);
                    }}
                    fullWidth
                    disabled={resultLock}
                    required
                    autoFocus={!resultLock}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={resultLock ? handleShake : () => {}}
                >
                  <MDInput
                    fontWeight="medium"
                    value={OverMeasure}
                    type="number"
                    label={
                      <div>
                        <InfoTooltip title="The total length of material lost removing unusable ends for stock." />
                        {"Factory End Removal"}
                      </div>
                    }
                    onChange={(event) => {
                      setOverMeasure(event.target.value);
                    
                    }}
                    fullWidth
                    disabled={resultLock}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={OptLength}
                    type="text"
                    disabled
                    label={
                      <div>
                        <InfoTooltip title="Length of optimizable stock." />
                        {"Cutting Stock Length"}
                      </div>
                    }
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  color={darkMode ? "grey-400" : "dark"}
                >
                  Optimize Settings{" "}
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={resultLock ? handleShake : () => {}}
                >
                  <MDInput
                    fontWeight="medium"
                    value={SawKerf}
                    type="text"
                    label={
                      <div>
                        <InfoTooltip title="Saw Kerf is the amount of material lost each time a cut is made." />
                        {"Saw-Kerf"}
                      </div>
                    }
                    onChange={(event) => {
                      setSawKerf(event.target.value);
                  
                    }}
                    fullWidth
                    disabled={resultLock}
                  />
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    className="BottomTrayButton"
                    onClick={toggleAdvanced}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>{isAdvancedOpen ? "remove" : "add"}</Icon>
                    &nbsp;{isAdvancedOpen ? "Hide Advanced" : "Show Advanced"}
                  </MDButton>
                </MDBox>
                <MDBox className="advancedON" ref={wrapperRef}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={usefulScrap}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="The minimum leftover stock length that the optimizer will attempt to use in cutting. This should never be set above the shortest part" />
                          {"Useful Scrap"}
                        </div>
                      }
                      onChange={(event) => {
                        setusefulScrap(event.target.value);
                   
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Iterations || "100"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="Refers to the process of iterating through successive generations of the genetic algorithm" />
                          {"Iterations"}
                        </div>
                      }
                      onChange={(event) => {
                        setIterations(event.target.value);
                 
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Generations}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="Pertains to the creation of a new randomly ordered population of parts" />
                          {"Generation Size"}
                        </div>
                      }
                      onChange={(event) => {
                        setGenerations(event.target.value);
                   
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Crossover || "0.8"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="Amount of genes passing from parent to child per generation 0 being none and 1 being All." />
                          {"CrossOver"}
                        </div>
                      }
                      onChange={(event) => {
                        setCrossover(event.target.value);
                       
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Mutation || "0.3"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="The probability of genes of the new generation mutating." />
                          {"Mutation"}
                        </div>
                      }
                      onChange={(event) => {
                        setMutation(event.target.value);
                       
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Randomness || "0.3"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="High values favor higher genetic randomness, while vaules closer to 0 favor using existing genetic Material." />
                          {"Randomness"}
                        </div>
                      }
                      onChange={(event) => {
                        setRandomness(event.target.value);
                      
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                </MDBox>
                {renderWarningSB}
              </Grid>
            </Grid>
          </MDBox>
        );
        break;
      case 1:
        content = (
          <div>
            <PartList
              parts={Parts}
              updateparts={updateparts}
              handleShake={handleShake}
              convertToMixedFraction={convertToMixedFraction}
              Fraction={Fraction}
              ToggleFraction={setFraction}
              FormData={{
                Die: DieName,
                Project: JobOptions.find((option) => option.key === DieProject),
              }}
              resultLock={resultLock}
            />
          </div>
        );
        break;
      case 2:
        if(TotalParts > Subscription.items[0].price.product.metadata.PartLimit){
          content = (
            <>
            Please upgrade your part limit is {Subscription.items[0].price.product.metadata.PartLimit} and this cutlist has {TotalParts} parts
            </>
          );
        }else{
          content = (
            <CuttingResults
            FractionView={Fraction}
            setFractionView={setFraction}
              FormData={{
                Die: DieName,
                Project: JobOptions.find((option) => option.key === DieProject),
                StockLength: StockLength,
                OverMeasure: OverMeasure,
                StockWeight: StockWeight,
                ImageURL:ImageURL,
                DieWeight: DieWeight,
                MaterialFinish: MaterialFinish,
                DieDesc: DieDesc,
                FormDate: FormDate,
                Parts: Parts,
              }}
              Results={results}
            />
          );
        }

        break;
      default:
        content = null;
    }
    return content;
  };
  const handleSetTabValue = async (event, newValue) => {
    setTabValue(newValue);
  };
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    const wrapper = ImageRef.current;
    const rect = wrapper.parentNode.parentNode.getBoundingClientRect();
    const scaleFactor = Math.max(rect.width, rect.height) / 100;
    if (!isClicked) {
      wrapper.style.zIndex = "99";
      wrapper.style.transform = `scale(1)`;
      wrapper.style.borderTopLeftRadius = "";
    } else if (wrapper.textContent != "p" && isClicked) {
      (wrapper.style.borderTopLeftRadius = "0"), (wrapper.style.zIndex = "9999999");
      wrapper.style.backgroundColor = "white";
      wrapper.style.transform = `scale(${scaleFactor})`;
    }
  }, [isClicked]);
  const handleClick = (event) => {
    setIsClicked(true);
  };
  const handleblur = (event) => {
    setIsClicked(false);
  };

  const handletest = (data) => {
    var test = data.apiRef.current || false;
    if (test) {
      setgridRef(data.apiRef.current);
    }
  };

  const handleDragEnter = () => {
    setIsDraggingOver(true);
  };

  // Define a function to update the state if needed
  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const [open, toggleOpen] = useState(false);

  const handleClose = () => {
    setDialogValue({
      title: "",
      year: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    title: "",
    year: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const InsertData = async (data) => {
      try {

        addMaterial({
          Weightft: data.DieWeight,
          Costft: data.Costft,
          SKU: data.SKU,
          Status: "1",
          Notes: data.Notes,
          Image:data.ImageURL,
          ImagePath:data.ImagePath,
          materialName: data.DieName,
          materialDesc: data.DieApplication,
          Material: data.Material,
        });
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    };
    InsertData(ModalData); //send for insert

  };
  const Optimize = (event) => {

    let count1 = 0;
    let remainingParts = [...Parts]; // Copy the array to avoid mutating the original
    let StockArray = [{ StockNumber: 1, RemainingLength: 288, PartCut: {} }]; // Initialize an array to hold stock lengths
    var TotalMaterialParts = 0;
    remainingParts.sort((a, b) => parseFloat(b[2]) - parseFloat(a[2])); // Sort parts by length (longest first)

    for (let index = 0; index < remainingParts.length; index++) {
      let partLength = parseFloat(remainingParts[index][2]);
      var partQty = remainingParts[index][1];
      if (!isNaN(remainingParts[index][1])) {
        partQty = parseInt(remainingParts[index][1]);
      }
      remainingParts[index][1] = partQty;
      remainingParts[index][2] = partLength;
      TotalMaterialParts += partQty * partLength;
      switch (remainingParts[index][3]) {
        case "Single Miter":
          partLength += 1;
          break;
        case "Double Miter":
          partLength += 2;
          break;
        default:
          break;
      }

      for (let P = partQty; P > 0; P--) {
        for (let i = 0; i < StockArray.length; i++) {
          if (StockArray[i].RemainingLength >= partLength) {
            StockArray[i].RemainingLength -= partLength + parseFloat(SawKerf); // Reduce the remaining length of the stock
            if (
              !Object.prototype.hasOwnProperty.call(StockArray[i].PartCut, remainingParts[index][0])
            ) {
              StockArray[i].PartCut[remainingParts[index][0]] = [
                remainingParts[index][0],
                1,
                partLength,
                remainingParts[index][3],
              ];
              count1++;
              // Add part to PartCut object if not already present
            } else {
              StockArray[i].PartCut[remainingParts[index][0]][1] += 1; // Increment quantity if part already exists in PartCut object
              count1++;
            }

            break;
          } else {
            if (i + 1 == StockArray.length) {
              let newStock = {
                StockNumber: StockArray.length + 1,
                RemainingLength: StockLength - (partLength + parseFloat(SawKerf)),
                PartCut: {},
              };
              newStock.PartCut[remainingParts[index][0]] = [
                remainingParts[index][0],
                1,
                partLength,
                remainingParts[index][3],
              ]; // Add part to PartCut object
              count1++;
              StockArray.push(newStock);
              break;
            }
          }
        }
      }
    }
    //setPartsList(temppart);

    const output = {
      Stock: StockLength,
      Sticks: StockArray.length,
      Results: [],
      OptYield: 0, // You need to define the calculateOptYield function
    };
    //yield will equal totalpartslength /(#stocks * length)
    StockArray.forEach((stock, index) => {
      let pickuplength = parseFloat(StockLength); //get sum of all previous parts in stock
      Object.keys(stock.PartCut).forEach((part) => {
        const partDetails = stock.PartCut[part];
        const resultItem = {
          qty: partDetails[1],
          part: partDetails[0],
          stock: `'S${index + 1}'`,
          cutLength: convertToMixedFraction(partDetails[2]) + '"',
          pickupStock: convertToMixedFraction(pickuplength) + '"',
          specialAction: partDetails[3], // You may need to modify this based on your requirements
          remainingStock:
            convertToMixedFraction(
              Math.max(pickuplength - partDetails[1] * (partDetails[2] + parseFloat(SawKerf)), 0)
            ) + '"',
          // You need to define the calculateRemainingStock function
        };
        pickuplength -=
          (parseFloat(partDetails[2]) + parseFloat(SawKerf)) * parseFloat(partDetails[1]);
        output.Results.push(resultItem);
      });
    });

    output.OptYield = TotalMaterialParts / (parseFloat(StockLength) * output.Sticks);
    setresults(output);
  };
  const OptColorMode = resultLock ? "#ffbfbf" : "#b2b2b2";
  const handleShake = () => {
    setIsShaking(true);
    setOptLock(true);
    // Remove the animation after it's completed
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="5rem"
        borderRadius="xl"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "25%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={ImageURL ||""}
              alt="profile-image"
              size="xl"
              shadow="sm"
              ref={ImageRef}
              className={isClicked ? "project-clicked" : ""}
              onClick={handleClick}
              onMouseLeave={handleblur}
              style={{
                transition: "transform 0.3s ease",
                cursor: "pointer",
                transformOrigin: "left calc(25% + 0px)",
                zIndex: "99",
              }}
            />
            {ModalOpen && SavedAlert}
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <Autocomplete
                disablePortal
                id="combo-box"
                disableClearable
                freeSolo
                options={Dieoptions}
                value={DieName || "New Shape"}
                onChange={(event, newValue) => {
          
                  if (typeof newValue === "string") {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                      toggleOpen(true);
                      const vat = newValue.label?.replace("+Add ", "");
                      setDialogValue({
                        title: vat,
                        year: "",
                      });
                      setDieName(newValue.inputValue);
                    });
                  } else if (newValue && newValue.inputValue) {
                    toggleOpen(true);
                    setDieName(newValue.inputValue);
                    setDialogValue({
                      title: newValue.inputValue,
                      year: "",
                    });
                  } else {
                    setDieName(newValue.label);
                  }
                }}
                getOptionLabel={(option) => {
                  // e.g. value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.label;
                  }
                  return option.label;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const existingOption = options.find(
                    (option) => option.label === params.inputValue
                  );

                  if (params.inputValue && !existingOption) {
                    filtered.push({
                      id: params.inputValue,
                      label: `+Add ${params.inputValue}`,
                      key: params.inputValue,
                    });
                  }

                  return filtered;
                }}
                renderOption={(optionProps, option) => (
                  <li
                    key={option.key}
                    className={optionProps.className}
                    onClick={(event) => {
      
                      const selectedOption = Dieoptions.find((die) => die.key === option.key);
                      if (selectedOption) {
                        setDieName(option.label);
                      } else {
                    
                        toggleOpen(true);
                        var remov = option.label?.replace("+Add ", "");
                        setDialogValue({
                          title: remov,
                          year: "",
                        });
    
                      }
                    }}
                  >
                    {option.label}
                  </li>
                )}
                selectOnFocus
                renderInput={(params) => <TextField {...params} label="Part Number" />}
              />
              <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <form onSubmit={handleSubmit}>
                  <DialogTitle sx={{ backgroundColor: colors[DarkString]?.main }}>
                    Add a new Material Shape
                  </DialogTitle>
                  <DialogContent sx={{ backgroundColor: colors[DarkString]?.main }}>
                    <DialogContentText></DialogContentText>
                    <DieShapeForm SubmitForm={ModalSubmit} Diedata={dialogValue.title} />
                  </DialogContent>
                  <DialogActions sx={{ backgroundColor: colors[DarkString]?.main }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                  </DialogActions>
                </form>
              </Dialog>
              <MDBox>
                <MDInput
                  fontWeight="medium"
                  value={DieDesc}
                  type="text"
                  label="Part Description"
                  onChange={(event) => {
                    setDieDesc(event.target.value);
             
                  }}
                  sx={{ mt: 2 }}
                />
                <MDButton
                  variant="gradient"
                  color="secondary"
                  title="Update Part Description From Material Library"
                  onClick={syncDesc}
                  sx={{ mt: "16px" }}
                >
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    sync
                  </Icon>
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Material"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
                <Tab
                  label="Parts"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      format_list_numbered_icon
                    </Icon>
                  }
                />

                <Tab
                  label={resultLock ? "Cutting" : "Optimize"}
                  title={resultLock ? "" : "Must Optimize First"}
                  className={resultLock  ? "OptimizeMode" : ""}
                  sx={{ color: { OptColorMode } }}
                  disabled={typeof Parts.length !="undefined" ? "" :"disabled"}
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      content_cut
                    </Icon>
                  }
                  onClick={(event) => {
                    if (!resultLock  && typeof Parts.length !="undefined" ) {
                      event.preventDefault();
                      Optimize();
                    }
                  }}
                />
              </Tabs>
            </AppBar>
          </Grid>
          {resultLock && renderOptLockSB}

          <Grid item>
            <MDButton variant="gradient" className={`${isDirty ? "isDirty" : ""}`} color={"info"} onClick={handleSave}>
              <Icon sx={{ fontWeight: "bold" }}>save</Icon>
              Save
            </MDButton>
          </Grid>

          {resultLock && (
            <Grid item>
              <MDButton
                variant="gradient"
                color="error"
                sx={{
                  transform: isShaking ? "translateY(-5px)" : "none",
                  transition: isShaking ? "transform 0.25s ease-in-out" : "none",
                }}
                onClick={handleClearOpt}
              >
                <Icon sx={{ fontWeight: "bold" }}>lockopen</Icon>
                Clear Optimization / Unlock
              </MDButton>
            </Grid>
          )}
        </Grid>
        {children({ tabValue })}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
  Diedata: {},
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  Diedata: PropTypes.object,
  setFraction:PropTypes.func,
  Fraction:PropTypes.bool,
};
//// linear cutting below

export default Header;
