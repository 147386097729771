/*eslint-disable no-unused-vars*/
import React, { useState, useEffect, useContext } from "react";
import { ProjectContext, AuthContext } from "../../context";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  useGridApiRef,
  GridActionsCellItem,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";

const Tables = () => {
  const apiRef = useGridApiRef();
  const { Materials, addMaterial } = useContext(ProjectContext);
  const { AppcurrentUser } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Materials) {
      const initialRows = Materials.map((material, index) => {
        const {
          Status = "Unknown",
          SKU = "",
          Image="",
          materialName = "",
          materialDesc = "",
          id = index + 1, // Use index as fallback for id
        } = material;


        return {
          id, // Ensure ID is unique and defaults to the current index
          Image,
          materialName,
          materialDesc,
          SKU,
          Status,
        };
      }).sort((a, b) => {
        if (a.Status < b.Status) {
          return -1;
        } else if (a.Status > b.Status) {
          return 1;
        } else {
          return a.materialName.localeCompare(b.materialName);
        }
      });

      setRows(initialRows);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [Materials]);

  const columns = [
    {
      field: "Status",
      headerName: "Open/Edit",
      flex: 0.25,
      renderCell: (params) => {
        return (
          <MDTypography component={Link} to={`/Material/${params.row.id}`}>
            <MDBadge
              badgeContent={"Open"}
              size="sm"
              container
              circular
              color={params.row.Status !== "1" ? "error" : "success"}
            />
          </MDTypography>
        );
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Open
        </MDTypography>
      ),
    },
    {
      field: "Image",
      headerName: "Image",
      flex: .25,
      renderCell: (params) => {
        return <MDAvatar src={params.row.Image} alt="Avatar" size="sm"/>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Image
        </MDTypography>
      ),
    },
    {
      field: "materialName",
      headerName: "materialName",
      flex: .5,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.materialName}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Material Name
        </MDTypography>
      ),
    },
    {
      field: "materialDesc",
      headerName: "materialDesc",

      flex: 0.5,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.materialDesc}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Description
        </MDTypography>
      ),
    },
    {
      field: "SKU",
      headerName: "SKU",
      flex: 0.5,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.SKU}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          SKU
        </MDTypography>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" sx={{ fontSize: "24px" }} color="white">
                      Material List
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      onClick={() =>
                        addMaterial({
                          Weightft: "",
                          Costft: "",
                          SKU: "",
                          Status: "1",
                          Notes: "",
                          materialName: "New",
                          materialDesc: "New",
                          Image:"",
                          ImagePath:"",
                        })
                      }
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;Add New Material
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <LinearProgress />
                ) : (
                  <DataGrid
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    autoHeight
                    moreActionsIcon
                    disableRowSelectionOnClick
                    loading={rows.length === 0}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Tables;
