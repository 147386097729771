/*eslint-disable no-unused-vars*/
import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {AuthContext } from "../../context";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import homeDecor1 from "assets/images/home-decor-1.jpg";
import team1 from "assets/images/team-1.jpg";

function Overview() {


  const { AppcurrentUser } = useContext(AuthContext);
  const [MyProjects, setMyProjects] = useState([]);




  useEffect(() => {
    if (AppcurrentUser) {
      /*
      const initialRows = Object.entries(projects)
        .filter(([key, project]) => project.Waltek_PM === User.ID)
        .map(([key, project]) => ({
          id: project.ID,
          Supers: JSON.parse(project.Super_IDs_JSON || "[]"),
          JobName: project.JobName,
          Abbriviation: project.Abbriviation,
          Job_Address2: project.Job_Address2,
          Status: project.Status,
          Installer: project.Installer,
          Job_Address1: project.Job_Address1,
          Job_Num: project.Job_Num,
          Super_IDs_JSON: JSON.parse(project.Super_IDs_JSON),
        }));
      setMyProjects(initialRows);*/
    }
  }, [ AppcurrentUser]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings follows={true} />
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />

              <ProfileInfoCard
                title="profile information"
                description={AppcurrentUser?.displayName
                }
                info={{
                  fullName: AppcurrentUser?.displayName || "",
                  mobile: AppcurrentUser?.phoneNumber
                  || "",
                  email: AppcurrentUser?.email || "",
                  location: "USA",
                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
                  
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Recent Projects
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={6}>
            {MyProjects.map((project, index) => (
              <Grid item xs={12} md={6} xl={3} key={project.id}>
                <DefaultProjectCard
                  image={homeDecor1}
                  label={project.Abbriviation}
                  title={project.JobName}
                  description="As Uber works through a huge amount of internal management turmoil."
                  action={{
                    type: "internal",
                    route: "/Projects/project-Details#" + project.id,
                    color: "info",
                    label: "view project",
                  }}
             
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
