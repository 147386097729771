/*eslint-disable no-unused-vars*/
import Container from "@mui/material/Container";
import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { getApp } from "firebase/app";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { AuthContext ,useMaterialUIController} from "../../context";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import MDTypography from "components/MDTypography";
import colors from "assets/theme-dark/base/colors";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
const app = getApp();
function Dashboard() {
/*we have collected products in authcontext, the features need to be added from the metadata */
  const { CheckoutSession } = useContext(AuthContext);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, sidenavColor } = controller;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const {Role , Subscription} = useContext(AuthContext);
  const handleSetTabValue = async (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setSelectedSubscription(Role);
  }, [Role]);
  const subscriptionLevels = [
    {
      id: "basic",
      name: "Basic",
      price: tabValue === 0 ? "$10/month" : "$100/year",
      priceID: tabValue === 0 ? "price_1QEsME07yZ3BH4Lp5dclllUO" : "price_1QEtTk07yZ3BH4LprhfMwypB",
      description: "Includes essential features for small projects.",
    },
    {
      id: "pro",
      name: "Pro",
      price: tabValue === 0 ? "$30/month" : "$300/year",
      priceID: tabValue === 0 ? "price_1QEtT607yZ3BH4Lpcg2H52oJ" : "price_1QEtUG07yZ3BH4LpG6CmV7xN",
      description: "Advanced tools for growing businesses.",
    },
    {
      id: "enterprise",
      name: "Enterprise",
      price: "Custom Pricing",
      priceID: "",
      href:"forward user to contact form",
      description: "Tailored solutions for large organizations.",
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Monthly"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: `${colors[sidenavColor].main}`, // Replace with your desired color
                      color: "#fff", // Optional: Change text color
                      transition: "background-color 300ms ease",
                    },
                  }}
                />
                <Tab
                  label="Yearly"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      format_list_numbered_icon
                    </Icon>
                  }
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: `${colors[sidenavColor].main}`, // Replace with your desired color
                      color: "#fff", // Optional: Change text color
                      transition: "background-color 300ms ease",
                    },
                  }}
                />
              </Tabs>
            </AppBar>
      <MDBox py={3}>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
 
              {subscriptionLevels.map((level) => (
          <Grid item xs={12} sm={6} md={4} key={level.id}>
            <Card
            color={sidenavColor}
              sx={{
                p: 3,
                border: selectedSubscription === level.id ? `2px solid ${colors[sidenavColor].main}` : "1px solid #e0e0e0",
                boxShadow: selectedSubscription === level.id ? "0px 4px 20px rgba(0,0,0,0.2)" : "none",
              }}
            >
              <MDBox textAlign="center">
                <MDTypography variant="h5" fontWeight="medium"  gutterBottom>
                  {level.name}
                </MDTypography>
                <MDTypography variant="h6"   gutterBottom>
                  {level.price}
                </MDTypography>
                <MDTypography variant="body2"   mb={3}>
                  {level.description}
                </MDTypography>
                <IconButton
                  color={selectedSubscription === level.id ? sidenavColor : "default"}
                  onClick={() => {
                    CheckoutSession(level.priceID);
                  }}
                >
                  <CheckCircleIcon />
                </IconButton>
              </MDBox>
            </Card>
          </Grid>
        ))}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
/*
<Projects />
              <OrdersOverview />*/
export default Dashboard;
